import {i18n} from "../I18n/I18n";
import {dynamicSort} from "../utils/SortUtils";

export default class Unit {
    static get CUBIC_METER() {
        return Object.freeze({id: 'CUBIC_METER', name: i18n('cubic_meter'), symbol: 'm³'});
    }

    static get DAY() {
        return Object.freeze({id: 'DAY', name: i18n('day'), symbol: 'dag'});
    }

    static get HOUR_OTHER() {
        return Object.freeze({id: 'HOUR_OTHER', name: i18n('hour_other'), symbol: 'h'});
    }

    static get HOUR_WORK() {
        return Object.freeze({id: 'HOUR_WORK', name: i18n('hour_work'), symbol: 'h'});
    }

    static get KILOMETER() {
        return Object.freeze({id: 'KILOMETER', name: i18n('kilometer'), symbol: 'km'});
    }

    static get LINEAL_METER() {
        return Object.freeze({id: 'LINEAL_METER', name: i18n('lineal_meter'), symbol: 'lm'});
    }

    static get METER() {
        return Object.freeze({id: 'METER', name: i18n('meter'), symbol: 'm'});
    }

    static get MILE() {
        return Object.freeze({id: 'MILE', name: i18n('mile'), symbol: i18n('mile')});
    }

    static get MONTH() {
        return Object.freeze({id: 'MONTH', name: i18n('month'), symbol: i18n('month_abbr').toLowerCase()});
    }

    static get PIECE() {
        return Object.freeze({id: 'PIECE', name: i18n('piece'), symbol: 'st'});
    }

    static get SQUARE_METER() {
        return Object.freeze({id: 'SQUARE_METER', name: i18n('square_meter'), symbol: 'm²'});
    }

    static get SWEDISH_KRONA() {
        return Object.freeze({id: 'SWEDISH_KRONA', name: i18n('swedish_krona'), symbol: 'kr'});
    }

    static values() {
        const result = [
            this.CUBIC_METER,
            this.DAY,
            this.HOUR_OTHER,
            this.HOUR_WORK,
            this.KILOMETER,
            this.MONTH,
            this.LINEAL_METER,
            this.METER,
            this.MILE,
            this.PIECE,
            this.SQUARE_METER,
            this.SWEDISH_KRONA,
        ];
        result.sort(dynamicSort('name'))
        return result
    }

    static findById(id) {
        return this.values().find(u => u.id === id)
    }
}