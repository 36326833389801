import React, {useContext, useState} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import {NBSP} from "../../../../../App";
import AppContext from "../../../../../AppContext";
import Button, {CancelButton, PdfButton, SaveButton} from "../../../../../components/Buttons/Button";
import FormItemDropdown from "../../../../../components/EditForm/FormItemDropdown";
import FormItemMultiSelect from "../../../../../components/EditForm/FormItemMultiSelect";
import FormItemProject from "../../../../../components/EditForm/FormItemProject";
import FormItemRowGrid from "../../../../../components/EditForm/FormItemRowGrid";
import FormItemText from "../../../../../components/EditForm/FormItemText";
import FormItemTextArea from "../../../../../components/EditForm/FormItemTextArea";
import CheckboxEditor from "../../../../../components/Grid/CheckboxEditor";
import EmployeeSelectEditor from "../../../../../components/Grid/EmployeeSelectEditor";
import ExtraMenu from "../../../../../components/Grid/ExtraMenu";
import {isFreeRow} from "../../../../../components/Grid/freeRows";
import onRowChange from "../../../../../components/Grid/onRowChange";
import {errorAlert} from "../../../../../components/Overlay/Alert";
import {confirmYes} from "../../../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../../../components/Overlay/Loading";
import Spinner from "../../../../../components/Spinner/Spinner";
import BillingType from "../../../../../enums/BillingType";
import Icon from "../../../../../enums/Icon";
import Role from "../../../../../enums/Role";
import useDialog from "../../../../../hooks/useDialog";
import useEmployees from "../../../../../hooks/useEmployees";
import useMountEffect from "../../../../../hooks/useMountEffect";
import usePreload, {PreloadType} from "../../../../../hooks/usePreload";
import useUserPersmissions from "../../../../../hooks/useUserPersmissions";
import {i18n} from "../../../../../I18n/I18n";
import {findById} from "../../../../../utils/ArrayUtil";
import {getDayName, getWeek} from "../../../../../utils/DateUtils";
import RouterUtils from "../../../../../utils/RouterUtils";
import ProjectService from "../../../../Projects/ProjectService";
import ProjectStatus from "../../../../Projects/ProjectStatus";
import DaylockService from "../../../DaylockService";
import DayplanningService from "../../DayplanningService";
import DayplanningMoveToOtherProjectDialog from "./DayplanningMoveToOtherProjectDialog";
import TimeregGrid from "./TimeregGrid";

const DayplanningEditForm = props => {
    const context = useContext(AppContext)
    const preload = usePreload()
    const userPersmissions = useUserPersmissions()

    const articleList = preload.get(PreloadType.ARTICLE_LIST)
    const employees = useEmployees()

    const [model, setModel] = useState();
    const [invoicerowtimeregList, setInvoicerowtimeregList] = useState();
    const [invoicerowtimeregarticleList, setInvoicerowtimeregarticleList] = useState();
    const [projectList, setProjectList] = useState();
    const [locked, setLocked] = useState();

    const location = useLocation()
    const navigate = useNavigate()

    const dialog = useDialog()

    const disabled = !userPersmissions.hasRole(Role.TIMEREG_WRITE) || locked;
    const getEmployee = id => employees.findById(id)

    const getId = () => matchPath({
        path: '/timereg/dayplanning/:id/:date', exact: true, strict: true
    }, location.pathname).params.id

    const date = matchPath({
        path: '/timereg/dayplanning/:id/:date', exact: true, strict: true
    }, location.pathname).params.date

    const defaultValues = {
        id: 0,
        company: context.currentCompany.id,
        date: date,
        todo: '',
        invoiceNote: '',
        workDone: '',
        note: '',
        timeregList: [],
        timeregarticleList: [],
    }

    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, setValue, control} = form

    const watchTimeregList = useWatch({control, name: 'timeregList'})
    const watchTimeregarticleList = useWatch({control, name: 'timeregarticleList'})

    const validate = (dayplanning) => {
        let validtimeregList = true
        dayplanning.timeregList.forEach(timereg => {
            if ((timereg.fixedHours !== 0 || timereg.costplusHours !== 0) && !timereg.salarytype) {
                errorAlert(i18n('error_salary_time_without_type'))
                validtimeregList = false
            }

            if (timereg.debitHours !== 0 && !timereg.debittype) {
                errorAlert(i18n('error_debit_time_without_type'))
                validtimeregList = false
            }
        })
        if (!validtimeregList) return false

        return true;
    }

    const save = async () => {
        const data = getValues();
        if (!disabled && validate(data)) {
            Object.assign(model, data);

            const saveModel = Object.assign({}, model)
            console.log("saveModel", saveModel)
            saveModel.moment = saveModel.momentArray ? saveModel.momentArray.join(',') : ''
            const _model = await DayplanningService.update(saveModel)
            if (_model) {
                setModel(_model)
                reset(_model)
            }
            return _model
        } else {
            return model;
        }
    }

    const onSubmit = () => {
        showLoading();
        return save().then(_model => {
            hideLoading();
            if (_model) {
                RouterUtils.goBack(navigate)
            }
        });
    }

    const onDownloadWorkorder = () => {
        showLoading();
        save().then(_model => {
            if (_model) {
                DayplanningService.downloadWorkorderPdf(_model.id)
            }
            hideLoading();
        })

    };

    const onRemoveDayplanning = () => {
        confirmYes(async () => {
            await DayplanningService.delete(model.id)
            RouterUtils.goBack(navigate)
        });
    }

    useMountEffect(async () => {
        const [
            _projectList,
            _locked
        ] = await Promise.all([
            ProjectService.findAll({
                status: ProjectStatus.ONGOING.id,
                startDate: date,
                endDate: date
            }),
            DaylockService.isLocked(date)
        ])
        setProjectList(_projectList)
        setLocked(_locked)


        let id = getId()
        if (id > 0) {
            showLoading()
            const [
                _model,
                _invoicerowtimeregList,
                _invoicerowtimeregarticleList
            ] = await Promise.all([
                DayplanningService.findById(id),
                DayplanningService.findInvoicerowtimeregsByDayplanning(id),
                DayplanningService.findInvoicerowtimeregarticlesByDayplanning(id)
            ])
            _model.momentArray = _model.moment.length > 0 ? _model.moment.split(',') : []
            setModel(_model);
            reset(_model);

            setInvoicerowtimeregList(_invoicerowtimeregList)
            setInvoicerowtimeregarticleList(_invoicerowtimeregarticleList)

            hideLoading()
        } else {
            setModel(defaultValues);
            reset(defaultValues);
        }
    }, [props, reset])

    function isAttestNeeded() {
        if (watchTimeregList.find(tr => !tr.notarized) !== undefined) {
            return true
        }
        if (watchTimeregarticleList.find(tr => !tr.notarized) !== undefined) {
            return true
        }
    }

    async function attest() {
        showLoading()
        const _timeregList = [...watchTimeregList]
        _timeregList.forEach(item => item.notarized = true)
        setValue('timeregList', _timeregList)

        const _timeregarticleList = [...watchTimeregarticleList]
        _timeregarticleList.forEach(item => item.notarized = true)
        setValue('timeregarticleList', _timeregarticleList)
        await handleSubmit(onSubmit)()
        hideLoading()
    }

    if (!model || !projectList) return <Spinner/>

    const dayString = getDayName(date) + ', ' + i18n('week').toLowerCase() + ' ' + getWeek(date)

    function getCustomColumns(rowList, fireChange) {
        return [
            {
                field: 'employee', header: i18n('employee'),
                body: row => <div className='nowrap'
                                  style={{width: '100%'}}>{getEmployee(row.employee)?.name}{NBSP}</div>,
                editor: e => isTimeregarticleInvoiced(e.rowData)
                    ? getEmployee(e.rowData.employee)?.name
                    : <EmployeeSelectEditor event={e}/>,
                onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
                width: 150,
            },
            {
                afterField: userPersmissions.isHideArticlePrices() ? 'quantity' : 'price',
                field: 'notarized', header: i18n('notarized'),
                // body: rowData => {
                //     if (isFreeRow(rowList, rowData)) {
                //         return ''
                //     }
                //
                //     return <Checkbox checked={rowData.notarized}
                //                      onChange={e2 => {
                //                          rowData.notarized = e2.target.checked
                //                          fireChange(rowList)
                //                      }}
                //                      disabled={disabled || isTimeregarticleInvoiced(rowData)}
                //     />
                // },
                body: (rowData, e) => isFreeRow(rowList, rowData)
                    ? ''
                    : CheckboxEditor(Object.assign(e, {rowData: rowData}), fireChange, disabled || isTimeregarticleInvoiced(rowData)),
                rendererIsEditor: true,
                align: 'center',
                width: 80
            },
        ]
    }

    function isTimeregarticleInvoiced(timeregarticle) {
        return invoicerowtimeregarticleList?.find(item => item.timeregarticle === timeregarticle.id) !== undefined
    }

    function onProjectChange(e) {
        const project = findById(projectList, e.target.value)
        if (project) {
            setValue('billingType', project.billingType)
        }
    }

    return (
        <div className="EditForm">
            <div className="headline">
                <h1>{i18n('day_planning')} {date} - {dayString}</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="button-row">
                    <div className='left-buttons'>
                        {!disabled && <SaveButton onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>}
                        <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                        {
                            isAttestNeeded() &&
                            <div style={{marginLeft: '40px'}}>
                                <Button faicon={Icon.NOTARIZE} label={i18n('notarize')} onClick={e => attest()}/>
                            </div>
                        }
                    </div>
                    <div className='divider'></div>

                    <div className='right-buttons'>
                        <PdfButton labelI18n='create_workorder' onClick={e => handleSubmit(onDownloadWorkorder)()}/>

                        <ExtraMenu itemList={[
                            {
                                label: i18n('move_to_other_project') + '...',
                                faicon: Icon.MOVE,
                                command: () => dialog.show(<DayplanningMoveToOtherProjectDialog
                                    projectList={projectList}
                                    dayplanning={model}
                                />),
                                disabled: locked,
                                hidden: invoicerowtimeregList?.length > 0 || invoicerowtimeregarticleList?.length > 0
                                    || !userPersmissions.hasRole(Role.TIMEREG_WRITE) || !model.id
                            },
                            {
                                label: i18n('remove'),
                                faicon: Icon.REMOVE,
                                command: () => onRemoveDayplanning(),
                                disabled: locked,
                                hidden: invoicerowtimeregList?.length > 0 || invoicerowtimeregarticleList?.length > 0
                                    || !userPersmissions.hasRole(Role.TIMEREG_WRITE) || !model.id
                            }
                        ]}/>
                    </div>
                </div>

                <div className='columnContainer'>
                    <div className='column'>
                        <FormItemProject options={projectList}
                                         onChange={onProjectChange}
                                         required {...form} disabled={model.id > 0}/>
                        <FormItemDropdown name='billingType' labelI18n='billing_type'
                                          options={BillingType.values()}
                                          showSelect
                                          showSelectValue={null}
                                          {...form} />
                        <FormItemDropdown name='invoiceNote' labelI18n='invoicing'
                                          options={[
                                              {id: i18n('continues'), name: i18n('continues')},
                                              {id: i18n('do_invoice'), name: i18n('do_invoice')},
                                              {id: i18n('invoiced'), name: i18n('invoiced')},
                                          ]}
                                          showSelect
                                          width={150}
                                          {...form} />
                    </div>
                    <div className='column'>
                        <FormItemText name='car' labelI18n='car' maxLength={16} {...form}/>
                        <FormItemTextArea name='todo' labelI18n='todo' maxLength={500} {...form}/>
                        <FormItemMultiSelect name='momentArray' labelI18n='moment' maxLength={128}
                                             optionValue='id'
                                             optionLabel='id'
                                             options={[
                                                 {id: 'Aluminium'},
                                                 {id: 'Stål'},
                                                 {id: 'Montage'},
                                                 {id: 'Demontage'},
                                                 {id: 'Ombyggnation'},
                                                 {id: 'Plast'},
                                                 {id: 'Nät'},
                                                 {id: 'Regnskydd'},
                                                 {id: 'Service'},
                                                 {id: 'Transport'},
                                                 {id: 'Väderskydd'},
                                             ]}
                            // options={[
                            //     'Aluminium',
                            //     'Stål',
                            //     'Montage',
                            //     'Demontage',
                            //     'Ombyggnation',
                            //     'Plast',
                            //     'Nät',
                            //     'Regnskydd',
                            //     'Service',
                            //     'Transport',
                            //     'Väderskydd',
                            // ]}
                                             {...form}/>
                    </div>
                    <div className='column'>
                        <FormItemTextArea name='workDone' labelI18n='work_done' maxLength={500} {...form}/>
                        <FormItemTextArea name='note' labelI18n='note' maxLength={500} {...form}/>
                    </div>
                </div>

                <div className="formItem">
                    <label htmlFor='timeregList'>{i18n('time_registration')}</label>
                    <Controller name="timeregList"
                                control={control}
                                render={({field: {ref, value, onChange}}) =>
                                    <TimeregGrid
                                        inputRef={ref} value={value} onChange={onChange}
                                        form={form}
                                        disabled={disabled}
                                        date={date}
                                        invoicerowtimeregList={invoicerowtimeregList}
                                    />
                                }/>
                </div>

                <div style={{marginTop: '40px'}}>
                    <FormItemRowGrid name='timeregarticleList' articleList={articleList} label={i18n('articles')}
                                     isCellEditable={(field, rowData) => !rowData.readonly && !isTimeregarticleInvoiced(rowData)}
                                     defaultRowValue={{
                                         id: 0,
                                         date: date,
                                         article: undefined,
                                         onlyTotal: false,
                                         name: '',
                                         quantity: 0,
                                         aprice: 0,
                                         discount: 0,
                                         price: 0,
                                         employeeComment: '',
                                         notarized: true,
                                         priceList: []
                                     }}
                                     numOfFreeRows={1}
                                     getCustomColumns={getCustomColumns}
                                     housework={false}
                                     footer={<></>}
                                     useArticleId={true}
                                     {...form}/>
                </div>
            </form>
            {dialog.render()}
        </div>
    );
}

export default DayplanningEditForm;
