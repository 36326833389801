import I18n from '@I18n';
import React, {useState} from 'react';
import {MainButton} from "../../../components/Buttons/Button";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import PdfColumn from "../../../components/Grid/PdfColumn";
import Spinner from "../../../components/Spinner/Spinner";
import {matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import useMountEffect from "../../../hooks/useMountEffect";
import {i18n} from "../../../I18n/I18n";
import InvoiceService from "../InvoiceService";
import InvoiceStatus from "../InvoiceStatus";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";

const InvoiceHistoryDialog = props => {
    const projectId = props.projectId;

    return <div>
        <XlnzDialog headerI18n='invoicing_history'
                    onHide={props.onHide}
                    buttons={props => [<MainButton key='close' labelI18n='close' onClick={e => props.onHide()}/>]}
        >
            <div style={{width: '800px'}}>
                <InvoiceingHistoryGrid projectId={projectId}/>
            </div>
        </XlnzDialog>
    </div>
}
export default InvoiceHistoryDialog;

export const InvoiceingHistoryGrid = ({name, projectId, updateQuantity}) => {
    const filter = useFilter('InvoiceingHistoryGrid', [
        {name: 'search', defaultValue: ''},
    ])
    const [list, setList] = useState()
    const filteredList = list?.filter(item => matchSearch(filter, item)) || []

    useMountEffect(async () => {
        const invoiceList = await InvoiceService.findByProject(projectId)

        const _list = []

        invoiceList.forEach(i => {
            i.rowList.forEach(ir => {
                _list.push({
                    id: i.id,
                    number: i.number,
                    invoiceDate: i.invoiceDate,
                    name: ir.name,
                    price: ir.price,
                    statusName: InvoiceStatus.getName(i.status)
                })
            })
        })
        if (updateQuantity) updateQuantity(_list.length)
        setList(_list)
    })

    const columns = [
        PdfColumn(() => true,
            rowData => rowData.number ? I18n.t('invoice') + ' ' + rowData.number : '',
            rowData => '/api/invoices/' + rowData.id + '/pdf'
        ),
        {field: 'number', headerI18n: 'invoice_number', width: 100},
        {field: 'invoiceDate', headerI18n: 'invoice_date', width: 100},
        {field: 'name', headerI18n: 'description'},
        {
            field: 'price',
            headerI18n: 'amount',
            body: GridTemplates.currencyTwoDecimals,
            align: 'right',
            width: 120,
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS
        },
        {field: 'statusName', headerI18n: 'status', width: 100},
    ]

    if (!list) return <Spinner/>
    return <Grid
        name={name + ' - ' + i18n('invoicing_history')}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        onRowSelect={() => {
        }}
        heightOffset={520}
    />
}