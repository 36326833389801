import usePreload, {PreloadType} from "../../../hooks/usePreload";
import React, {useState} from "react";
import useUserPersmissions from "../../../hooks/useUserPersmissions";
import {useFilter} from "../../../components/Grid/filter";
import {matchSearch} from "../../../functions/match";
import {findById} from "../../../utils/ArrayUtil";
import useMountEffect from "../../../hooks/useMountEffect";
import {GridTemplates} from "../../../GridTemplates";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Spinner from "../../../components/Spinner/Spinner";
import Grid from "../../../components/Grid/Grid";
import {i18n} from "../../../I18n/I18n";

export default function ProjectSummaryTimeregarticleGrid({project, dayplanningList, updateQuantity}) {
    const preload = usePreload()
    const [list, setList] = useState()

    const userPersmissions = useUserPersmissions()

    const filter = useFilter('ProjectSummaryCostplusArticleGrid', [
        {name: 'search', defaultValue: ''},
    ])

    const HIDE_ARTICLE_PRICES = userPersmissions.isHideArticlePrices()

    const filteredList = list?.filter(item => matchSearch(filter, item)) || []

    const employeeList = preload.get(PreloadType.EMPLOYEE_LIST)
    const getEmployeeName = id => id !== null ? findById(employeeList, id).name : ''
    const articleList = preload.get(PreloadType.ARTICLE_LIST)
    const getArticleName = id => id !== null ? findById(articleList, id)?.name : ''

    useMountEffect(async () => {
        const _list = []
        dayplanningList.forEach(dp => {
            dp.timeregarticleList.forEach(tr => {
                _list.push({
                    date: dp.date,
                    employeeName: getEmployeeName(tr.employee),
                    articleName: getArticleName(tr.article?.id),
                    name: tr.name,
                    unit: tr.unit,
                    quantity: tr.quantity,
                    aprice: tr.aprice,
                    discount: tr.discount,
                    price: tr.price,
                    notarized: tr.notarized,
                })
            })
        })

        updateQuantity(_list.length)
        setList(_list)
    })

    const columns = [
        {field: 'date', headerI18n: 'date', width: 100},
        {field: 'employeeName', headerI18n: 'employee', width: 200},
        {
            field: 'articleName',
            headerI18n: 'article',
        },
        {field: 'name', headerI18n: 'description',},
        {
            field: 'unit', headerI18n: 'unit',
            body: GridTemplates.unit,
            width: 100,
        },
        {
            field: 'quantity',
            headerI18n: 'quantity',
            body: GridTemplates.numberTwoDecimals,
            width: 120,
            align: 'right'
        },
        {
            field: 'aprice',
            headerI18n: 'aprice',
            body: GridTemplates.currencyTwoDecimals,
            width: 120,
            align: 'right',
            hide: HIDE_ARTICLE_PRICES
        },
        {
            field: 'discount',
            headerI18n: 'discount',
            body: GridTemplates.percentTimes100,
            width: 80,
            align: 'right',
            hide: HIDE_ARTICLE_PRICES
        },
        {
            field: 'price',
            headerI18n: 'sum',
            body: GridTemplates.currencyTwoDecimals,
            width: 120,
            align: 'right',
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            hide: HIDE_ARTICLE_PRICES
        },
    ].filter(item => !item.hide)

    if (!filteredList) return <Spinner/>
    return <Grid
        name={project.name + ' - ' + i18n('articles')}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        onRowSelect={() => {
        }}
        heightOffset={520}
        rowClassName={e => ({'redRow': !e.notarized})}
    />
}