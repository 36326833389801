import React from 'react';
import {DebounceInput} from "react-debounce-input";
import useMediaQuery from "../../hooks/useMediaQuery";
import {i18n} from "../../I18n/I18n";
import {getModule} from "../../modules/modules";
import NetUtils from "../../utils/NetUtils";
import XlnzGrid from "../XlnzGrid/XlnzGrid";
import ExtraMenu from "./ExtraMenu";
import './Grid.scss';
import GridLabel from "./GridLabel";
import MobileGrid from "./MobileGrid";

const Grid = props => {
    const {
        id,
        disabled,
        sortable = true,
        onSortFieldChange,
        onSortOrderChange,
        name,
        labelI18n,
        excelLabelI18n,
        icon,
        gridLabelRightContent,
        leftFilters,
        rightFilters,
        hideRightFilters,
        onExportToExcel,
        filter,
        updateFilter,
        uberColumns,
        columns,
        rowClassName,
        value,
        width,
        heightOffset,
        rowSelectParams = '',
        selection,
        onSelectionChange,
        onRowReorder,
        scrollable = true,
        pagination = false,
        forceDesktop = false,
        hideHeader = false,
        hideFooter = false,
        hideExtraMenu = false,
        customFooter,
        cellClassName
    } = props

    let onRowSelect = props.onRowSelect
    let menuItems = props.menuItems

    const mediaQuery = useMediaQuery()

    const contextMenu = props.contextMenu
        ?.map(item => {
            const _item = {...item}
            _item.originalCommand = item.command
            _item.command = () => _item.originalCommand()
            // _item.command = () => _item.originalCommand(contextMenuSelection)

            _item.originalTemplate = item.template
            _item.template = (item, options) => _item.originalTemplate(item, options)
            // _item.template = (item, options) => _item.originalTemplate(item, options, contextMenuSelection)

            return _item
        })


    let module = getModule(window.location.hash.substring(1))
    if (!onRowSelect && module) {
        if (module.onRowSelectPath) {
            onRowSelect = e => {
                window.location.href = '/#' + module.onRowSelectPath + '/' + e.data.id + rowSelectParams
            }
        } else {
            const path = module?.routeProps?.path || module?.path
            onRowSelect = e => window.location.href = '/#' + path + '/' + e.data.id + rowSelectParams
        }
    }

    const filterHeight = document.getElementById('filter')?.offsetHeight || 0

    return <div id={id} className="Grid">
        <div className='grid-label'>
            <GridLabel icon={icon} label={labelI18n ? i18n(labelI18n) : props.label}/>
            <div style={{flexGrow: '1'}}></div>
            <div className='right'>{gridLabelRightContent}</div>
        </div>
        {
            filter &&
            <div id='filter' className="filter">
                {leftFilters && leftFilters.map((f, index) => <div key={index}>{f}</div>)}

                <div style={{flexGrow: 1, padding: 0}}></div>

                {rightFilters && rightFilters.map((f, index) => <div key={index}>{f}</div>)}
                {
                    !hideRightFilters &&
                    <>
                        <div>
                            <label className="label">{i18n('search')}</label>
                            <DebounceInput
                                name='search'
                                value={filter.search}
                                className="search"
                                // minLength={2}
                                debounceTimeout={300}
                                onChange={(e) => {
                                    if (updateFilter) updateFilter('search', e.target.value)
                                    else filter.update('search', e.target.value)
                                }}/>
                        </div>


                        {
                            !hideExtraMenu &&
                            <div style={{paddingRight: 0}}>
                                <label className="label">&nbsp;</label>
                                <ExtraMenu itemList={menuItems}
                                           list={value}
                                           onExportToExcel={() => {
                                               if (onExportToExcel) onExportToExcel()
                                               else {
                                                   NetUtils.exportToExcel(name
                                                           ? name
                                                           : excelLabelI18n ? i18n(excelLabelI18n) : i18n(labelI18n)
                                                       , uberColumns, columns, value)
                                               }
                                           }
                                           }/>
                            </div>
                        }
                    </>
                }
            </div>
        }

        {
            mediaQuery.isMobile() && !forceDesktop &&
            <MobileGrid value={value} columns={columns} onRowSelect={onRowSelect}/>
        }

        {
            (!mediaQuery.isMobile() || forceDesktop) &&
            <XlnzGrid
                id={id}
                value={value}
                disabled={disabled}
                uberColumns={uberColumns}
                columns={columns}
                sortable={sortable}
                onSortFieldChange={onSortFieldChange}
                onSortOrderChange={onSortOrderChange}
                onRowSelect={e => {
                    if (!onRowSelect) return
                    // if (e.originalEvent.target.className !== 'p-editable-column') {
                    onRowSelect(e)
                    // }
                }}
                width={width}
                scrollHeight={scrollable
                    ? ('calc(100dvh - ' + (heightOffset ? heightOffset : filterHeight + 140) + 'px)')
                    : undefined}
                pagination={pagination}
                contextMenuOptions={contextMenu}
                hideHeader={hideHeader}
                hideFooter={hideFooter}
                customFooter={customFooter}
                onRowReorder={disabled ? undefined : onRowReorder}
                selection={selection}
                onSelectionChange={onSelectionChange}
                rowClassName={rowClassName}
                cellClassName={cellClassName}
            />
        }
    </div>
}

export default Grid;

